import Head from 'next/head';
import { useRouter } from 'next/router';

export default function HeadSite() {
  const route = useRouter().asPath;
  const formatRoutName = (str) => {
    if (str === '/') return 'Home';
    str = str.replace(/^\//, '');
    str = str.replace(/-/g, ' ');
    str = str.replace(/\b[a-z]/g, (x) => x.toUpperCase());

    return str;
  };

  return (
    <Head>
      <title>{route ? formatRoutName(route) : null}</title>
      <link
        rel='shortcut icon'
        href='/static/favicon.ico'
      />
      <link
        rel='preload'
        href='/fonts/Barlow/Barlow-Medium.ttf'
        as='font'
        crossOrigin=''
        type='font/ttf'
      />
      <link
        rel='preload'
        href='/fonts/Khand/Khand-Medium.ttf'
        as='font'
        crossOrigin=''
        type='font/ttf'
      />
    </Head>
  );
}
