import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';

import useMedia from '../../hooks/useMedia';

import style from './contactBar.module.scss';

export default function ContactBar() {
  const telefone = '+5541995150201';
  const isMobile = useMedia('(max-width: 768px)');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const styleModal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: 'none',
    outline: 'none',
    boxShadow: 24,
    p: 4,
  };

  const initCall = () => {
    window.open(`tel:${telefone}`);
  };

  const contentModal = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <button
        type='button'
        style={{
          display: 'flex',
          width: '100%',
          color: '#353e52',
          background: 'transparent',
          border: 'none',
          marginTop: '-1.5rem',
          position: 'absolute',
          justifyContent: 'flex-end',
          padding: '0.5rem 0.8rem',
        }}
        onClick={handleClose}>
        <CloseIcon sx={{ cursor: 'pointer', color: 'inherit' }} />
      </button>
      <h4>Atendimento por telefone</h4>
      <h5 style={{ display: 'flex', alignItems: 'center' }}>
        <FiPhoneCall />
        &nbsp;&nbsp;(41)&nbsp;&nbsp;
        {telefone.slice(5, 10)}
        -
        {telefone.slice(10)}
      </h5>
    </div>
  );

  return (
    <div className={style.contactBar}>
      <h3 className={style.title}>Vendas Online</h3>

      <button
        type='button'
        className={style.option}
        onClick={() => window.open('https://dehut.app/HXLSBO/botao-whats-hype', '_blank')}
        aria-label='Enviar mensagem no WhatsApp da Hype'>
        <div>
          <FaWhatsapp />
        </div>
        <span>
          Atendimento por
          {' '}
          <b>WhatsApp</b>
        </span>
      </button>

      <button
        type='button'
        className={style.option}
        onClick={() => {
          document.location = '#formContainer';

          return false;
        }}
        aria-label='Enviar e-mail para a Hype'>
        <div>
          <HiOutlineMail />
        </div>
        <span>
          Atendimento por
          {' '}
          <b>E-mail</b>
        </span>
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={styleModal}>{contentModal()}</Box>
      </Modal>

      <button
        type='button'
        aria-label='Ligar para a Hype'
        className={style.option}
        onClick={isMobile ? () => initCall() : handleOpen}>
        <div>
          <FiPhoneCall />
        </div>
        <span>
          Atendimento por
          {' '}
          <b>Telefone</b>
        </span>
      </button>
    </div>
  );
}
