import { useRouter } from 'next/router';

import { IoIosArrowDropright } from 'react-icons/io';

import styles from './conhecaNossasOpcoes.module.scss';

export default function ConhecaNossasOpcoesButton() {
  const router = useRouter();

  return (
    <button
      type='button'
      className={styles.section}
      aria-label='Abrir página com apartamentos a venda'
      onClick={
        router.pathname === '/invista-na-hype'
          ? () => window.open(
            'https://api.whatsapp.com/send?phone=5541997744020&text=Ol%C3%A1,%20tenho%20interesse%20em%20investir%20na%20Hype.%20Podemos%20conversar?',
            '_blank',
          )
          : () => router.push('/apartamentos-a-venda')
      }>
      <span>
        {router.pathname === '/invista-na-hype'
          ? 'Fale com um de nossos assessores de Investimento!'
          : 'Conheça nossas opções de apartamentos à venda!'}
        <IoIosArrowDropright />
      </span>
    </button>
  );
}
