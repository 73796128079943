import React, { ReactNode } from 'react';

import { useScroll } from '../../hooks/useScroll';

import styles from './styles.module.scss';

interface BottomBarProps {
  children: ReactNode;
}

export default function BottomBar({ children }: BottomBarProps) {
  const isVisible = useScroll();

  return <div className={`${styles.bottomBar} ${isVisible ? styles.visible : styles.hidden}`}>{children}</div>;
}
