import { ReactNode } from 'react';

import { useScroll } from '../../../hooks/useScroll';

import styles from './styles.module.scss';

interface LeftFloatingContainerProps {
  children: ReactNode;
}

export default function LeftFloatingContainer({ children }: LeftFloatingContainerProps) {
  const isVisible = useScroll();

  return <div className={`${styles.FloatingContainer} ${isVisible ? styles.visible : styles.hidden}`}>{children}</div>;
}
