import { ReactNode } from 'react';

import Footer from './Footer';
import Header from './HeaderAcc';

interface LytSiteProps {
  children: ReactNode;
  headerData: any;
  footerData: any;
}

export default function LytSite({ children, headerData, footerData }: LytSiteProps) {
  return (
    <>
      <Header data={headerData} />
      {children}
      <Footer data={footerData} />
    </>
  );
}
