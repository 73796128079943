export default function RoutesHeader(routes) {
  const unicodeSimbol = String.fromCodePoint(0x25bc);

  const itemsHeader = routes && [
    {
      label: routes[0]?.descricao,
      href: routes[0]?.item,
    },
    {
      label: `Institucional ${unicodeSimbol}`,
      href: '#',
      subItems: [
        {
          label: routes[1]?.descricao,
          href: routes[1]?.item,
        },
        {
          label: routes[2]?.descricao,
          href: routes[2]?.item,
        },
        {
          label: routes[3]?.descricao,
          href: routes[3]?.item,
        },
        {
          label: routes[4]?.descricao,
          href: routes[4]?.item,
        },
      ],
    },
    {
      label: `Apartamentos ${unicodeSimbol}`,
      href: '#',
      subItems: [
        {
          label: routes[5]?.descricao,
          href: routes[5]?.item,
        },
        {
          label: routes[6]?.descricao,
          href: routes[6]?.item,
        },
      ],
    },
    {
      label: routes[7]?.descricao,
      href: routes[7]?.item,
    },
    {
      label: routes[8]?.descricao,
      href: routes[8]?.item,
    },
    {
      label: `+Hype ${unicodeSimbol}`,
      href: '#',
      subItems: [
        {
          label: routes[9]?.descricao,
          href: routes[9]?.item,
        },
        {
          label: routes[10]?.descricao,
          href: routes[10]?.item,
        },
        {
          label: routes[11]?.descricao,
          href: routes[11]?.item,
        },
      ],
    },
  ];

  return itemsHeader || [];
}
