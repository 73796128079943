import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const useScroll = () => {
  const [isVisible, setIsVisible] = useState(false);
  const route = useRouter().pathname;

  const listenToScroll = () => {
    let heightToShowFrom = 200;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (route == '/apartamentos-a-venda' || route == '/') {
      setIsVisible(true);
    } else {
      if (winScroll < heightToShowFrom) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    setIsVisible(false);

    if (route === '/apartamentos-a-venda' || route === '/') {
      setIsVisible(true);
    }
  }, [route]);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    window.addEventListener('resize', listenToScroll);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
      window.removeEventListener('resize', listenToScroll);
    };
  }, []);

  return isVisible;
};
